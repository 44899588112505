import { useController } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
} from '@chakra-ui/react';

const XInput = (props) => {
  const { control, name, id, label, rules, isRequired, ...xprops } = props || {};
  const {
    field: { onChange, value, },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <>
      <FormControl isInvalid={invalid} id={id} isRequired={isRequired}>
        <FormLabel>{label}</FormLabel>
        <Input
          value={value}
          onChange={onChange}
          {...xprops}
        />
        <FormErrorMessage>{error && error.message}</FormErrorMessage>
      </FormControl>
    </>
  )
};

export default XInput;
