import React, { useId, useState, useCallback } from 'react';
import {
  Flex,
  Button,
  ButtonGroup,
  useBoolean,
  IconButton,
  Collapse,
} from '@chakra-ui/react';
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

import XLocation from 'containers/XLocation';
import XJTKeyword from 'containers/XJTKeyword';
import XJobType from 'containers/XJobType';
import XJExp from 'containers/XJExp';
import XQual from 'containers/XHQual';

const XForm = (props) => {
  const { t } = useTranslation();
  const { handleSearch } = props;
  const [isMoreFiltersOpen, setMoreFiltersOpen] = useState(false);
  const xid = useId();
  const xname = `${xid}-jtorkw`;
  const formDefaultValues = {
    [xname]: "",
  };
  
  const { handleSubmit, control } = useForm({
    defaultValues: formDefaultValues,
  });
  const [isLoading, setLoading] = useBoolean(false);
  const onSubmit = async (data) => {
    console.log("DATA", data);
    setLoading.on();
    console.log(isLoading, data);
    await handleSearch(data);
    setLoading.off();
  }

  const handleMoreFilters = () => {
    setMoreFiltersOpen(!isMoreFiltersOpen);
  }

  return (
    <form className="x-jb-filters" onSubmit={handleSubmit(onSubmit)}>
      <Flex className="x-jb-filters-inner" minWidth='max-content' alignItems='center' gap='2'>
        <XJTKeyword xid={xid} xcontrol={control} />
        <XLocation xid={xid} xcontrol={control} />
        <XJExp
          xid={xid}
          xcontrol={control}
          xnameprefix="min"
          xlabel={t("Min. Exp")}
          xplaceholder="1"
        />
        <XJExp
          xid={xid}
          xcontrol={control}
          xnameprefix="max"
          xlabel={t("Max. Exp")}
          xplaceholder="5"
        />
        {
          !isMoreFiltersOpen && (
            <Flex className="x-jf-btng-wrap">
              <ButtonGroup className="x-jf-btng" mt={8}>
                <Button
                  colorScheme='blue'
                  type='submit'
                  isLoading={isLoading}
                >
                  { t("Search") }
                </Button>

                <IconButton
                  icon={<MdArrowDownward />}
                  variant="outline"
                  disabled={isLoading}
                  onClick={() => { handleMoreFilters() }}
                >
                  { t("Advance") }
                </IconButton>
              </ButtonGroup>
            </Flex>
          )
        }
      </Flex>
      <Collapse
        in={isMoreFiltersOpen}
        animateOpacity={false}
        className="x-jf-collapse"
      >
        <Flex mt={4} minWidth='max-content' alignItems='center' gap='2'>
          <XJobType xid={xid} xcontrol={control} />
          <XQual xid={xid} xcontrol={control} />
        </Flex>
        <Flex className="x-jf-btng-wrap">
          <ButtonGroup className="x-jf-btng" mt={8}>
            <Button
              colorScheme='blue'
              type='submit'
              isLoading={isLoading}
            >
              { t("Search") }
            </Button>

            <IconButton
              icon={<MdArrowUpward />}
              variant="outline"
              disabled={isLoading}
              onClick={() => { handleMoreFilters() }}
            >
              { t("Advance") }
            </IconButton>
          </ButtonGroup>
        </Flex>
      </Collapse>
    </form>
  );
}

export default XForm;
