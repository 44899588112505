import { Box, Flex, Input,  } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import XInput from "containers/XInput";



export default function XJTKeyword(props) {
  const { t } = useTranslation();
  const label = t("Job Title / Keyword");
  const ph = t("Enter title or keyword") + ' ' + t("comma separated for multiple");
  const {
    xlabel = label,
    xplaceholder = ph,
    xcontrol,
    xid,
  } = props || {};
  const xname = `${xid}-jtorkw`;
  return (
    <Flex flex={1} className="xcs-jtorkw-input">
      <XInput
        control={xcontrol}
        name={xname}
        id={xname}
        placeholder={xplaceholder}
        label={xlabel}
      />
    </Flex>
  )
}