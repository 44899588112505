import React, { useState } from 'react';
import {
  Box,
  Container,
} from '@chakra-ui/react';

import XJobsList from 'containers/XJobsList';

import { useGetJobs } from 'hooks/useJobs';
import { getQkeyFromFilters } from "helpers/utils";
import XForm from 'containers/XForm';

const XHome = (props) => {
  const { careersWebSite, siteData } = props;
  const [page, setPage] = useState(1);
  const qkey = getQkeyFromFilters({}, 'xjobs');
  const [filters, setFilters] = useState(qkey || '-');
  const perPage = 15;
  const offset = perPage * (page - 1);
  const qdata = useGetJobs({
    ...filters,
    limit: perPage,
    offset,
    siteData,
  }, 'xjobs');
  const { data } = qdata || {};
  const { count, } = data || {};
  console.log('siteDatae', siteData);
  return (
    <Box my={4}>
      <Container className="x-hm-con" maxW="1080px">
        <XForm handleSearch={setFilters}/>
        <XJobsList
          qdata={qdata}
          perPage={perPage}
          count={count}
          page={page}
          setPage={setPage}
          offset={offset}
          careersWebSite={careersWebSite}
        />
      </Container>
    </Box>
  );
}

export default XHome;
