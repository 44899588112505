import { useQuery } from "react-query";
import xapi from "helpers/fetch";

const getCities  = async () => {
  try {
    let xurl = `/jobs/p/filters?type=location&limit=10000`;
    const config = {
      url: xurl,
      method: 'get',
    };
    const j = await xapi(config);
    const { data: k } = await j || {};
    const { filters } = k || {};
    const l = (filters || []).map((n,i) => {
      return {
        value: n.k,
        label: n.k,
      }
    });
    return l || [];
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const useGetCities = (d) => {
  const { stateName } = d || {};
  const xdata = useQuery(
    ['xcities', stateName || '-x-'],
    () => getCities(d),
    {
      refetchOnWindowFocus: false,
      retry: 3,
    }
  );
  return xdata;
}

