import React, { useEffect, useState, useRef } from 'react';
import {
  useParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Container,
  Progress,
  Text,
  Button,
  Checkbox,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  FormControl,
  FormLabel,
  Input,
  Select,
  useBoolean,
  InputGroup,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { MdKeyboardArrowRight, } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import { usePostCVToTP } from 'hooks/usePostCv';

import XStates from 'containers/XStates';
import XCities from 'containers/XCities';
import XInput from "containers/XInput";
import XFunction from 'containers/XFunction';
import XSector from 'containers/XSector';
import XEmployers from 'containers/XEmployers';
import XHQual from 'containers/XHQual';
import XGender from 'containers/XGender';
import XExperience from 'containers/XExperience';

import { getFileBase64 } from 'helpers/utils';

const RenderTextField = (props) => {
  const { f, rname } = props || {};
  const { name, control } = f || {};
  return (
    <XInput
      type="text"
      control={control}
      name={`x-tp-${rname}`}
      id={`x-tp-${rname}`}
      placeholder={name}
      label={name}
      my={2}
      isRequired
    />
  )
};

const RenderNumberField = (props) => {
  const { f, rname } = props || {};
  const { name } = f || {};
  return (
    <FormControl isRequired>
      <FormLabel>{name}</FormLabel>
      <Input name={`x-tp-${rname}`} type='number' />
    </FormControl>
  )
};

const RenderListField = (props) => {
  const { f, rname } = props || {};
  const { id, name, value } = f || {};
  return (
    <FormControl isRequired>
      <FormLabel>{name}</FormLabel>
      {
        Array.isArray(value) && (
          <Select name={`x-tp-${id}`} placeholder={`Select ${name}`}>
            {
              value.map(v => {
                const { label, value: oval } = v || {};
                return (
                  <option value={oval}>{label}</option>
                )
              })
            }
          </Select>
        )
      }
    </FormControl>
  )
};

const RenderApplyFields = (props) => {
  const { f, control, rname } = props || {};
  const { type, } = f || {};
  let rt = null;
  switch (type) {
    case 'text':
      rt = <RenderTextField rname={rname} f={f} control={control} />;
      break;
    case 'number':
      rt = <RenderNumberField rname={rname} f={f} control={control} />;
      break;
    case 'list':
      rt = <RenderListField rname={rname} f={f} control={control} />;
      break;
    default:
      rt = null;
  }
  return <Text as="div" className="x-appl-field" my={2}>
    {rt}
  </Text>
};

const XTPUpload = (props) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const { jobId } = useParams();
  const toast = useToast();
  const [isLoading, setLoading] = useBoolean(false);
  const [curState, setLState] = useState(null);
  const [haveExp, setExpState] = useState(null);
  const [cvDoc, setCv] = useState(null);
  const [applySuccess, setApplySuccess] = useState(false);

  const formDefaultValues = {
    "x-tp-fname": "",
    "x-tp-lname": "",
    "x-tp-email": "",
    "x-tp-mobile": "",
    "x-tp-state": "",
    "x-tp-city": "",
    "x-tp-dob": "",
    "x-tp-rcv": "",
  };

  const { handleSubmit, control, formState, watch, reset } = useForm({
    defaultValues: formDefaultValues,
  });

  const handleClick = () => {
    fileInputRef.current?.click()
  }

  const handleChange = (value) => {
    if (value.target.files) {
      const fobj = value.target.files?.[0];
      setCv(fobj);
    }
  }
  const xcurstate = watch('x-tp-state');
  const xexpbool = watch('x-tp-exp-bool');

  useEffect(() => {
    if (formState.dirtyFields['x-tp-state']) {
      const { label } = xcurstate || {};
      setLState(label);
    }
  }, [xcurstate, formState, setLState]);

  useEffect(() => {
    if (formState.dirtyFields['x-tp-exp-bool']) {
      const { label } = xexpbool || {};
      setExpState(label);
    }
  }, [xexpbool, formState, setExpState]);

  useEffect(() => {
    reset({
      "x-tp-fname": "",
      "x-tp-lname": "",
      "x-tp-email": "",
      "x-tp-mobile": "",
      "x-tp-state": "",
      "x-tp-city": "",
      "x-tp-dob": "",
      "x-tp-rcv": "",
    });
    setCv("");
  }, [reset, applySuccess])

  const { mutate } = usePostCVToTP((err, res) => {
    setLoading.off();
    if (res) {
      toast({
        status: 'success',
        duration: 10000,
        isClosable: true,
        render: () => (
          <Alert
            status='success'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            height='200px'
          >
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              {t("Resume uploaded")}!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              {t("Thanks for submitting your resume. Our team will get back to you soon.")}
            </AlertDescription>
          </Alert>
        ),
      });
      setApplySuccess(true);
    } else {
      toast({
        title: t("Unable to process"),
        description: t("Error occured while submitting the application"),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  });

  const onSubmit = async (data) => {
    if (!cvDoc) {
      toast({
        title: t("Missing CV/Resume"),
        description: t("No document attached, please attach CV/Resume"),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const cvBase64 = await getFileBase64(cvDoc);
    if (cvBase64) {
      data.cvBase64 = cvBase64;
    } else {
      alert(t("Unable to read attached CV/Resume, continuing..."));
    }
    const mobile = (data && data['x-tp-mobile']) || '';
    if (!mobile || (mobile.length < 6)) {
      toast({
        title: t("Incorrect Mobile"),
        description: t("Please enter valid mobile number"),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setLoading.on();
    mutate({
      jobId: jobId,
      ...data,
    });
  }

  return (
    <Box className='x-tp-upload'>
      <Helmet>
        <title>{t('join_our_talent_pool', 'Join our Talent Pool')}</title>
      </Helmet>
      <Box my={4}>
        <Container maxW="1200px">
          <>
            <Box
              boxShadow="1px 0px 10px rgba(0,0,0,0.07)"
              p='3'
              rounded='md'
              borderRadius={4}
              justifyContent="center"
              alignItems="center"
              my={4}
            >
              <Text fontSize={24} align="center" fontWeight="bold" my="4" as="h1">{t("join_talent_pool_heading", "Join our talent pool and we will reach out to you when we have a suitable opportunity")}</Text>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="x-appl-field">
                  <input
                    type="hidden"
                    name="x-tp-jobid"
                    id="x-tp-jobid"
                    value={jobId}
                  />
                </div>
                <Flex direction={{ base: 'column', md: 'row' }} mb={2}>
                  <div className="x-appl-field">
                    <XInput
                      type="text"
                      control={control}
                      name="x-tp-fname"
                      id="x-tp-fname"
                      placeholder={t("First Name")}
                      label={t("First Name")}
                      my={2}
                      isRequired
                    />
                  </div>
                  <div className="x-appl-field">
                    <XInput
                      type="text"
                      control={control}
                      name="x-tp-lname"
                      id="x-tp-lname"
                      placeholder={t("Last Name")}
                      label={t("Last Name")}
                      my={2}
                      isRequired
                    />
                  </div>
                  <div className="x-appl-field">
                    <XInput
                      type="email"
                      control={control}
                      name="x-tp-email"
                      id="x-tp-email"
                      placeholder={t("Email")}
                      label={t("Email")}
                      my={2}
                      isRequired
                    />
                  </div>
                  <div className="x-appl-field">
                    <XInput
                      type="text"
                      control={control}
                      name="x-tp-mobile"
                      id="x-tp-mobile"
                      placeholder={t("Mobile")}
                      label={t("Mobile")}
                      my={2}
                      isRequired
                    />
                  </div>
                </Flex>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <div className="x-appl-field" style={{ "width": "auto" }}>
                    <XInput
                      type="date"
                      control={control}
                      name="x-tp-dob"
                      id="x-tp-dob"
                      placeholder=""
                      label={t("Date of Birth")}
                      my={2}
                    />
                  </div>
                </Flex>
                <Box className="x-appl-field">
                  <Text as="div" mb={1}>
                    {t("Browse")}: *
                  </Text>
                  <InputGroup onClick={handleClick}>
                    <Input
                      type="file"
                      name="x-appl-rcv"
                      id="x-appl-rcv"
                      label={t("Upload CV")}
                      onChange={handleChange}
                      accept=".doc,.docx,.pdf,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      ref={fileInputRef}
                      hidden
                    />
                    <Flex>
                      <Button paddingX={12}>
                        {(cvDoc && cvDoc.name) ? cvDoc.name : t("Upload CV/Resume")}
                      </Button>
                    </Flex>
                  </InputGroup>
                  <Text>{t("PDF or Word Documents are allowed")}</Text>
                </Box>
                <Text as="div" style={{padding: 10}}>
                  <Checkbox defaultChecked>
                    {t("Receive WhatsApp / SMS / Email notications?")}</Checkbox>
                </Text>
                <Button
                  colorScheme='blue'
                  type='submit'
                  isLoading={isLoading}
                  my={4}
                >
                  {isLoading ? t("Uploading...") : t("Upload")}
                </Button>
              </form>
              <Text>
                {
                  t(`tpupload-notes`)
                }
              </Text>
            </Box>
          </>
        </Container>
      </Box>
    </Box>
  );
}

export default XTPUpload;
