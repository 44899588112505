import { Flex, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function XJobsPagination(props) {
  const { t } = useTranslation();
  const { page, setPage, count, offset, perPage } = props || {};
  
  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const handleNext = () => {
    setPage(page + 1);
  };
  let totalPages = "-";
  const divisor = perPage;
  if(divisor && count) {
    totalPages = Math.ceil(count / perPage);
  }
  let hasMore = true;
  if ((offset + perPage) >= count) {
    hasMore = false;
  }

  return (
    <Flex alignItems="center">
      <Flex maxW="400px" justifyContent="center" margin="auto" gap={2}>
        <Button disabled={page < 2} onClick={() => { handlePrev() }}>{ t("Prev") }</Button>
        <Button disabled={!hasMore} onClick={() => { handleNext() }}>{ t("Next") }</Button>
      </Flex>
      <Flex>
        { t("Total") }: {count} - { t("Page") } {page} { t("of") } {totalPages}
      </Flex>
    </Flex>
  )
}