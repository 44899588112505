import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import XDropdown from "containers/XDropdown";

const jobtypes = [
  {
    "value": 1,
    "label": "Permanent"
  },
  {
    "value": 2,
    "label": "Contract"
  },
  {
    "value": 3,
    "label": "Temporary"
  },
  {
    "value": 4,
    "label": "Internship"
  },
  {
    "value": 6,
    "label": "Trainee"
  },
];

export default function XIndustry(props) {
  const { t } = useTranslation();
  const label = t("Job Type");
  const ph = t("Select Job Type");
  const {
    xlabel = label,
    xplaceholder = ph,
    xoptions,
    xcontrol,
    xid,
  } = props || {};
  let options = jobtypes;
  if (Array.isArray(xoptions)) {
    options = xoptions;
  }
  const xname = `${xid}-jobtype`;

  return (
    <Flex flex={1} className="xcs-jobtype-dropdown">
      <XDropdown
        control={xcontrol}
        isMulti={false}
        name={xname}
        id={xname}
        options={options}
        placeholder={xplaceholder}
        label={xlabel}
        classNamePrefix="xcs"
      />
    </Flex>
  )
}