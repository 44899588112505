import { useQuery } from "react-query";

import xapi from "helpers/fetch";

const getCompanyInfo = async (qp) => {
  try {
    const xurl = `/companies/a/details`;
    const config = {
      url: xurl,
      method: 'get',
    }
    if(qp) {
      config.params = {
        ...qp,
      };
    }
    const j = await xapi(config);

    const { data: k } = j;
    return k || {};
  } catch (err) {
    console.error(err);
    return {};
  }
};


export const useCompanyInfo = (data) => {
  const {
    cid,
  } = data || {};
  const qkey = `c-${cid || 0}`;
  const xdata = useQuery(
    ['xci', qkey], () => getCompanyInfo(data),
    {
      refetchOnWindowFocus: false,
    }
  );
  return xdata;
};
