import { useMutation } from "react-query";

import xapi from "helpers/fetch";
import { getPageAndSiteKeys } from "helpers/common";

const postApplication = async (data) => {
  try {
    const xid = `xcsite-${Date.now()}` || {};
    const jid = (data && data['jobId']) || 0;
    const firstName = (data && data['x-apl-fname']) || 'NA';
    const lastName = (data && data['x-apl-lname']) || '';
    const email = (data && data['x-apl-email']) || '';
    const mobile = (data && data['x-apl-mobile']) || '';
    const dob = (data && data['x-apl-dob']) || '';
    const fullName = `${firstName|| 'NA'} ${lastName|| ' '}`;
    const { cvBase64 } = data || {};

    const {
      xpid,
      xsn,
    } = getPageAndSiteKeys();
    const lkey = `xsk-${xpid}:${xsn}`;
    let skData = localStorage.getItem(lkey);
    let xapiSource = ''
    if(!skData) {
      skData = localStorage.getItem('xskey');
    }
    if (skData) {
      const {
        sourceName,
      } = JSON.parse(skData) || {};
      if(sourceName) {
        xapiSource = sourceName;
      }
    }
    if(!xapiSource) {
      xapiSource = 'careersite';
    }
    const pdata = {
      externalCandidateId: xid,
      email,
      lastName,
      firstName,
      preferName: fullName,
      name: fullName,
      contacts: [{
        mobile,
      }],
      portalSource: xapiSource,
      x0paJobId: jid || 0,
      dob,
      dateOfBirth: dob,
      cvBase64,
    };
    const xurl = `/ext/${xapiSource}/pa`;
    const config = {
      url: xurl,
      method: 'post',
      data: JSON.stringify(pdata),
    }
    const j = await xapi(config);
    const { data: k } = j;
    return k || {};
  } catch (err) {
    console.error(err);
    return {};
  }
};


export const usePostAppl = (cb) => {
  const xdata = useMutation((data) => postApplication(data),
    {
      onSuccess: (sdata) => {
        const {
          xAppId,
        } = sdata || {};
        if(xAppId) {
          cb(null, sdata);
        } else {
          console.log(sdata);
          cb("Error occured while processing.", null);
        }
      },
      onError: () => {
        alert("there was an error");
        cb("Error occured while processing.", null);
      },
      onSettled: () => {

      }
    }
  );
  return xdata;
};
