import { useQuery } from "react-query";

import xapi from "helpers/fetch";

const getJob = async (jobId) => {
  try {
    const xurl = `/jobs/p/details/${jobId}?view=full`;
    const j = await xapi({
      url: xurl,
      method: 'get',
    });
    const { data: k } = j;
    return k || {};
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const useGetJob = (jobId) => {
  const xdata = useQuery(
    ['xjob', jobId || Date.now()],
    () => getJob(jobId),
    {
      refetchOnWindowFocus: false,
      retry: 3,
    }
  );
  return xdata;
};
