import React from 'react';
import {
  useParams,
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import {
  Box,
  Flex,
  Container,
  Stack,
  Skeleton,
  Progress,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Tag,
  TagLabel,
  Divider,
} from '@chakra-ui/react';
import { MdKeyboardArrowRight, } from "react-icons/md";
import { FaTags } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

import { useGetJob } from 'hooks/useJob';
import XJob from "containers/XJob";

const XJobDetail = (props) => {
  const { t } = useTranslation();
  const { jobId } = useParams();
  const { data: job, status, isFetching } = useGetJob(jobId);
  const {
    jobName, clientName,
    jobDesc, sectorName, stateName,
    countryName, skills, jobType,
    industryName, cityName,
    qualTypeName, termName,
    functionName, minExp, maxExp,
    recruiterCompanyId,
  } = job || {};

  let blink;
  let bname;
  // if (sectorName) {
  //   blink = `/jobs/sectors/${sectorName}`;
  //   bname = sectorName;
  // } else if (stateName) {
  //   blink = `/jobs/states/${stateName}`;
  //   bname = stateName;
  // } else if (countryName) {
  //   blink = `/jobs/countries/${countryName}`;
  //   bname = countryName;
  // }

  let locStr = '';
  if (stateName) {
    locStr += `${stateName}`;
  }
  if (cityName) {
    if (locStr) {
      locStr += `, ${cityName}`;
    } else {
      locStr += ` ${cityName}`;
    }
  }

  let expStr = '';
  let minExpStr = minExp;
  if(minExp == '0') {
    minExpStr = "0"
  }
  if (minExp || maxExp) {
    expStr = `${minExpStr || ""} - ${maxExp || ""} years`;
  }
  if (maxExp == '0') {
    expStr = `Fresher`;
  }

  let xcls = 'x-job-detail';
  if (recruiterCompanyId) {
    xcls += ` x-cl-${recruiterCompanyId}`;
  }
  const { careersWebSite } = props;
  return (
    <Box className={xcls}>
      <Helmet>
        <title>{jobName || t("No job")}</title>
      </Helmet>
      <Box my={4}>
        <Container maxW="1200px">
          {
            (!careersWebSite) && (
              <Breadcrumb my="4" separator={<MdKeyboardArrowRight color='gray.500' />} textTransform="capitalize">
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to='/'>{t("Home")}</BreadcrumbLink>
                </BreadcrumbItem>
                {
                  blink && (
                    <BreadcrumbItem>
                      <BreadcrumbLink as={Link} to={blink || "/"}>{bname || '--'}</BreadcrumbLink>
                    </BreadcrumbItem>
                  )
                }
                <BreadcrumbItem isCurrentPage isLastChild>
                  <BreadcrumbLink isCurrentPage>{t("Job details")}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
            )
          }
          {
            isFetching && <Progress my="2" size='xs' isIndeterminate />
          }
          {(status === "loading") && <>
            <Stack>
              <Skeleton height='20px' />
              <Skeleton height='20px' />
              <Skeleton height='20px' />
            </Stack>
          </>
          }
          {
            status === "success" && (
              <>
                <XJob xdata={job} jobDetailPage careersWebSite={careersWebSite} />
                <Box
                  px={8}
                  py={4}
                  mt={4}
                  borderRadius={4}
                  borderWidth={1}
                  borderColor="#eee"
                  className='xcs-job-details-summary'
                >
                  {
                    (locStr) && (<Text as="p">
                      Location: {locStr}
                    </Text>)
                  }
                  {
                    (expStr) && (<Text as="p">
                      Experience: {expStr}
                    </Text>)
                  }
                  {
                    industryName && (<Text as="p">
                      Sector: {industryName}
                    </Text>)
                  }
                  {
                    functionName && (<Text as="p">
                      Job Function: {functionName}
                    </Text>)
                  }
                  {
                    qualTypeName && (<Text as="p">
                      Qualification: {qualTypeName}
                    </Text>)
                  }
                  {
                    termName && (<Text as="p">
                      Job Type: {termName}
                    </Text>)
                  }
                  {
                    Array.isArray(skills) && (skills.length > 0) && (<>
                      <Text as="p">
                        {t("Skills")}:
                      </Text>
                      <Flex spacing={2} my={2} flexWrap="wrap">
                        {skills.map((s) => (
                          <Tag
                            size="lg"
                            key={s}
                            variant='solid'
                            colorScheme="blue"
                            ml={1}
                          >
                            <TagLabel>{s && s.skillName}</TagLabel>
                          </Tag>
                        ))}
                      </Flex>
                    </>
                    )
                  }
                </Box>
                <Box
                  p={8}
                  mt={4}
                  borderRadius={4}
                  borderWidth={1}
                  borderColor="#eee"
                >
                  {/* <Text fontWeight="bold" my={2}>{t("Job Description")}</Text> */}
                  <Box>
                    <div>{parse(jobDesc || "<div>NA</div>")}</div>
                  </Box>
                </Box>
              </>
            )
          }
          {
            status === "error" && (
              <Box
                boxShadow="1px 0px 20px rgba(0,0,0,0.07)"
                px={8}
                py={4}
                rounded='md'
                borderRadius={4}
                justifyContent="center"
                alignItems="center"
                maxH="150"
              >
                <Text fontSize='2xl'>
                  {t("Unable to fetch job details")}
                </Text>
              </Box>
            )
          }
        </Container>
      </Box>
    </Box>
  );
}

export default XJobDetail;
