const getPageAndSiteKeys = () => {
  try {
    const usp = new URLSearchParams(window.location.search);
    let xpid = usp.get("xpid");
    let xsn = usp.get("xsn");
    if(!xsn && xpid) {
      xsn = xpid;
    }
    if(!xsn && window.xgsn) {
      xsn = window.xgsn;
    }
    if(!xpid && window.xgpid) {
      xpid = window.xgpid;
    }
    let xl = (window.location !== window.parent.location)
    ? document.referrer
    : document.location.href;
    let xorigin = window.location.origin;
    if(xl) {
      const uo = new URL(xl);
      xorigin = uo.origin;
      if((xl.includes('accountstaff.x0pa.ai')) || (xl.includes('rpostag.x0pa.ai'))) {
        xpid = 'accountstaff';
        xsn = 'accountstaff';
      }
    }
    console.log('document.referrer', document.referrer);
    console.log('document.location.href', document.location.href);
    console.log('window.location.host', window.location.host);
    const d = {
      xpid,
      xsn,
      xorigin,
    };
    console.log(d)
    return d;
  } catch(err) {
    return {};
  }
};

export {
  getPageAndSiteKeys,
};
