import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const usp = new URLSearchParams(window.location.search);
const lng = usp.get("xlng");

const lngOptions = {
  debug: false,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
};

if(lng) {
  lngOptions.lng = lng;
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(lngOptions);

export default i18n;