import * as React from 'react';
import { ChakraProvider, extendTheme, Flex, Text, Spinner } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider, } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import XRoutes from 'routes/routes';

import './app.css';

const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
};

const theme = extendTheme({ colors });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const XLoader = () => {
  return (<Flex
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    w="100vw"
    height="100vh"
  >
    <Spinner
      thickness='4px'
      speed='0.65s'
      emptyColor='gray.200'
      color='blue.500'
      size='xl'
    />
    <Text as="h4" my={4}>Loading...</Text>
  </Flex>)
};

function App() {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <React.Suspense fallback={<XLoader />}>
          <XRoutes />
        </React.Suspense>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ChakraProvider>
  )
}

export default App;
