import { Box, Grid, GridItem, Text, Progress, Stack, Skeleton, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import XJob from "containers/XJob";
import XJobsPagination from 'containers/XJobsPagination';

export default function XJobsList(props) {
  const { t } = useTranslation();
  const { qdata, perPage, count, page, setPage, offset, careersWebSite } = props || {};
  const { data: xjobsdata, status, isFetching } = qdata || {};
  const { jobs, } = xjobsdata || {};

  return (
    <Box>
      <Grid
        className="xcs-jlist"
        // templateColumns='repeat(2, 1fr)'
        gap={6}
        autoFlow
        my={4}
      >
        {
          isFetching && <Progress my="2" size='xs' isIndeterminate />
        }
        {
          (status === "loading") && [1,2,3,4].map(j => {
            return <GridItem key={j}>
              <Stack>
              <Skeleton height='20px' />
              <Skeleton height='20px' />
              <Skeleton height='20px' />
            </Stack>
            </GridItem>
          })
        }
        {
          (status === "success") && Array.isArray(jobs) && (
            <div>Total: {count}</div>
          )
        }
        {
          (status === "success") && Array.isArray(jobs) && (jobs.length > 0) && jobs.map(j => {
            const { jobId } = j || {};
            return <GridItem key={jobId}><XJob xdata={j} careersWebSite={careersWebSite} /></GridItem>
          })
        }
        {
          (status === "success") && Array.isArray(jobs) && (jobs.length === 0) && (
            <Box
              boxShadow="1px 0px 20px rgba(0,0,0,0.07)"
              p='8'
              rounded='md'
              borderRadius={4}
              borderLeft="1px solid #ff0000"
              justifyContent="center"
              alignItems="center"
              maxH="200"
            >
              <Text fontSize='2xl'>
                {
                  t("No positions found, try changing the filters")
                }
              </Text>
              <Text as="div">
                <Text as="h4" mt={2}>
                  {
                    t("Did not find any jobs searching for? Join our Talent Pool by uploading resume")
                  }
                </Text>
                <Button 
                  colorScheme='blue'  
                  rounded="2rem"
                  as={Link}
                  to={`/c/r/tp`}
                  my={4}
                >
                  Upload Resume
                </Button>
              </Text>
            </Box>
          )
        }
      </Grid>
      <XJobsPagination
        perPage={perPage}
        count={count}
        page={page}
        setPage={setPage}
        offset={offset}
      />
    </Box>
  )
}