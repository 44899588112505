import React, { useEffect, useState, useRef } from 'react';
import {
  useParams,
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Container,
  Progress,
  Text,
  Button,
  Checkbox,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  FormControl,
  FormLabel,
  Input,
  Select,
  useBoolean,
  InputGroup,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { MdKeyboardArrowRight, } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import { useGetJob } from 'hooks/useJob';
import { usePostAppl} from 'hooks/usePostAppl';

import XStates from 'containers/XStates';
import XCities from 'containers/XCities';
import XInput from "containers/XInput";
import XFunction from 'containers/XFunction';
import XSector from 'containers/XSector';
import XEmployers from 'containers/XEmployers';
import XHQual from 'containers/XHQual';
import XGender from 'containers/XGender';
import XExperience from 'containers/XExperience';

import { getFileBase64 } from 'helpers/utils';

const RenderTextField = (props) => {
  const { f, rname } = props || {};
  const { name, control } = f || {};
  return (
    <XInput
      type="text"
      control={control}
      name={`x-apl-${rname}`}
      id={`x-apl-${rname}`}
      placeholder={name}
      label={name}
      my={2}
      isRequired
    />
  )
};

const RenderNumberField = (props) => {
  const { f, rname } = props || {};
  const { name } = f || {};
  return (
    <FormControl isRequired>
      <FormLabel>{name}</FormLabel>
      <Input name={`x-apl-${rname}`}  type='number' />
    </FormControl>
  )
};

const RenderListField = (props) => {
  const { f, rname } = props || {};
  const { id, name, value } = f || {};
  return (
    <FormControl isRequired>
      <FormLabel>{name}</FormLabel>
      {
        Array.isArray(value) && (
          <Select name={`x-apl-${id}`} placeholder={`Select ${name}`}>
            {
              value.map(v => {
                const { label, value: oval } = v || {};
                return (
                  <option value={oval}>{label}</option>
                )
              })
            }
          </Select>
        )
      }
    </FormControl>
  )
};

const RenderApplyFields = (props) => {
  const { f, control, rname } = props || {};
  const { type,  } = f || {};
  let rt = null;
  switch (type) {
    case 'text':
      rt = <RenderTextField rname={rname} f={f} control={control} />;
      break;
    case 'number':
      rt = <RenderNumberField rname={rname} f={f} control={control} />;
      break;
    case 'list':
      rt = <RenderListField rname={rname} f={f} control={control} />;
      break;
    default:
      rt = null;
  }
  return <Text as="div" className="x-appl-field" my={2}>
    {rt}
  </Text>
};

const XJobApply = (props) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const { jobId } = useParams();
  const toast = useToast();
  const xid = 'x-apl';
  const [isLoading, setLoading] = useBoolean(false);
  const [curState, setLState] = useState(null);
  const [haveExp, setExpState] = useState(null);
  const [cvDoc, setCv] = useState(null);
  const [applySuccess, setApplySuccess] = useState(false);

  const formDefaultValues = {
    "x-apl-fname": "",
    "x-apl-lname": "",
    "x-apl-email": "",
    "x-apl-mobile": "",
    "x-apl-state": "",
    "x-apl-city": "",
    "x-apl-dob": "",
    "x-apl-rcv": "",
  };

  const { handleSubmit, control, formState, watch, reset } = useForm({
    defaultValues: formDefaultValues,
  });
  const { data: job, status, isFetching } = useGetJob(jobId);
  const {
    jobName,
    sectorName, stateName,
    countryName,
    fields,
  } = job || {};

  const handleClick = () => {
    fileInputRef.current?.click()
  }

  const handleChange = (value) => {
    if(value.target.files) {
      const fobj = value.target.files?.[0];
      setCv(fobj);
    }
  }

  let blink;
  let bname;
  // if (sectorName) {
  //   blink = `/jobs/sectors/${sectorName}`;
  //   bname = sectorName;
  // } else if (stateName) {
  //   blink = `/jobs/states/${stateName}`;
  //   bname = stateName;
  // } else if (countryName) {
  //   blink = `/jobs/countries/${countryName}`;
  //   bname = countryName;
  // }

  const xcurstate = watch('x-apl-state');
  const xexpbool = watch('x-apl-exp-bool');

  useEffect(() => {
    if (formState.dirtyFields['x-apl-state']) {
      const { label } = xcurstate || {};
      setLState(label);
    }
  }, [xcurstate, formState, setLState]);

  useEffect(() => {
    if (formState.dirtyFields['x-apl-exp-bool']) {
      const { label } = xexpbool || {};
      setExpState(label);
    }
  }, [xexpbool, formState, setExpState]);

  useEffect(() => {
    reset({
      "x-apl-fname": "",
      "x-apl-lname": "",
      "x-apl-email": "",
      "x-apl-mobile": "",
      "x-apl-state": "",
      "x-apl-city": "",
      "x-apl-dob": "",
      "x-apl-rcv": "",
    });
    setCv("");
  }, [reset, applySuccess])

  const { mutate } = usePostAppl((err, res) => {
    setLoading.off();
    if(res) {
      toast({
        status: 'success',
        duration: 10000,
        isClosable: true,
        render: () => (
          <Alert
            status='success'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            height='200px'
          >
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              {t("Application submitted")}!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              {t("Thanks for submitting your application. Our team will get back to you soon.")}
            </AlertDescription>
          </Alert>
        ),
      });
      setApplySuccess(true);
    } else {
      toast({
        title: t("Unable to process"),
        description: t("Error occured while submitting the application"),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  });

  const onSubmit = async (data) => {
    if(!cvDoc) {
      toast({
        title: t("Missing CV/Resume"),
        description: t("No document attached, please attach CV/Resume"),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const cvBase64 = await getFileBase64(cvDoc);
    if(cvBase64) {
      data.cvBase64 = cvBase64;
    } else {
      alert(t("Unable to read attached CV/Resume, continuing..."));
    }
    const mobile = (data && data['x-apl-mobile']) || '';
    if(!mobile || (mobile.length < 6)) {
      toast({
        title: t("Incorrect Mobile"),
        description: t("Please enter valid mobile number"),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setLoading.on();
    mutate({
      jobId: jobId,
      ...data,
    });
  }
  const { careersWebSite } = props;
  return (
    <Box className='x-job-apply'>
      <Helmet>
        <title>{jobName || t("No job")}</title>
      </Helmet>
      <Box my={4}>
        <Container maxW="1200px">
          {
            !careersWebSite && (
              <Breadcrumb my="4" separator={<MdKeyboardArrowRight color='gray.500' />} textTransform="capitalize">
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to='/'>{t("Home")}</BreadcrumbLink>
                </BreadcrumbItem>
                {
                  blink && (
                    <BreadcrumbItem>
                      <BreadcrumbLink as={Link} to={blink || "/"}>{bname || '--'}</BreadcrumbLink>
                    </BreadcrumbItem>
                  )
                }
                <BreadcrumbItem isCurrentPage isLastChild>
                  <BreadcrumbLink isCurrentPage>{t("Job details")}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
            )
          }
          {
            isFetching && <Progress my="2" size='xs' isIndeterminate />
          }
          {
            status === "success" && (
              <>
                <Box
                  boxShadow="1px 0px 10px rgba(0,0,0,0.07)"
                  p='3'
                  rounded='md'
                  borderRadius={4}
                  justifyContent="center"
                  alignItems="center"
                  maxH="150"
                >
                  <Text fontWeight="bold" mb="1">{t("Applying to")}:</Text>
                  <Text as="span" fontSize="smaller" mb="1">{t("ID")}: {jobId}</Text>
                  <Text as="h4" fontWeight="bold" fontSize="20px">
                    {jobName}
                  </Text>
                </Box>
                <Box
                  boxShadow="1px 0px 10px rgba(0,0,0,0.07)"
                  p='3'
                  rounded='md'
                  borderRadius={4}
                  justifyContent="center"
                  alignItems="center"
                  my={4}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="x-appl-field">
                      <input
                        type="hidden"
                        name="x-apl-jobid"
                        id="x-apl-jobid"
                        value={jobId}
                      />
                    </div>
                    <Flex direction={{ base: 'column', md: 'row' }} mb={2} className="x-jp-form-wrap">
                      <div className="x-appl-field">
                        <XInput
                          type="text"
                          control={control}
                          name="x-apl-fname"
                          id="x-apl-fname"
                          placeholder={t("First Name")}
                          label={t("First Name")}
                          my={2}
                          isRequired
                        />
                      </div>
                      <div className="x-appl-field">
                        <XInput
                          type="text"
                          control={control}
                          name="x-apl-lname"
                          id="x-apl-lname"
                          placeholder={t("Last Name")}
                          label={t("Last Name")}
                          my={2}
                          isRequired
                        />
                      </div>
                      <div className="x-appl-field">
                        <XInput
                          type="email"
                          control={control}
                          name="x-apl-email"
                          id="x-apl-email"
                          placeholder={t("Email")}
                          label={t("Email")}
                          my={2}
                          isRequired
                        />
                      </div>
                      <div className="x-appl-field">
                        <XInput
                          type="text"
                          control={control}
                          name="x-apl-mobile"
                          id="x-apl-mobile"
                          placeholder={t("Mobile")}
                          label={t("Mobile")}
                          my={2}
                          isRequired
                        />
                      </div>
                    </Flex>
                    <Flex>
                      <div className="x-appl-field" style={{"width": "auto"}}>
                        <XInput
                          type="date"
                          control={control}
                          name="x-apl-dob"
                          id="x-apl-dob"
                          placeholder=""
                          label={t("Date of Birth")}
                          my={2}
                        />
                      </div>
                    </Flex>
                    <Box className="x-appl-field">
                      <Text as="div" mb={1}>
                        { t("Browse") }: *
                      </Text>
                      <InputGroup onClick={handleClick}>
                        <Input
                          type="file"
                          name="x-appl-rcv"
                          id="x-appl-rcv"
                          label={t("Upload CV")}
                          onChange={handleChange}
                          accept=".doc,.docx,.pdf,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          ref={fileInputRef}
                          hidden 
                        />
                        <Flex>
                          <Button paddingX={12}>
                            {(cvDoc && cvDoc.name) ? cvDoc.name : t("Upload CV/Resume")}
                          </Button>
                        </Flex>
                      </InputGroup>
                      <Text>{t("PDF or Word Documents are allowed")}</Text>
                    </Box>
                    <div className="x-appl-field">
                      <Checkbox defaultChecked>
                        { t("Receive WhatsApp / SMS / Email notications?") }</Checkbox>
                    </div>
                    <Button
                      colorScheme='blue'
                      type='submit'
                      isLoading={isLoading}
                      my={4}
                    >
                      {isLoading ? t("Applying...") : t("Apply")}
                    </Button>
                  </form>
                  <Text>
                    {
                      t(`apply-notes`)
                    }
                  </Text>
                </Box>
              </>
            )
          }
        </Container>
      </Box>
    </Box>
  );
}

export default XJobApply;
