import { useController } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
} from '@chakra-ui/react';
import {
  Select,
  createFilter,
  components,
} from "chakra-react-select";

const CustomOption = ({ children, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option {...newProps} className="x-rs-custom-option">
      {children}
    </components.Option>
  );
};

const XDropdown = (props) => {
  const { control, name, id, label, rules, isRequired, fclassName, ...xprops } = props || {};
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <>
      <FormControl
        isRequired={isRequired}
        isInvalid={invalid}
        id={id}
        className={fclassName || ''}
      >
        <FormLabel>{label}</FormLabel>
        <Select
          isMulti
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          menuPortalTarget={document.body}
          isClearable
          filterOption={createFilter({ ignoreAccents: false })}
          captureMenuScroll={false}
          components={{ Option: CustomOption }}
          {...xprops}
        />
        <FormErrorMessage>{error && error.message}</FormErrorMessage>
      </FormControl>
    </>
  )
};

export default XDropdown;
