import { getPageAndSiteKeys } from "helpers/common";

const getRchar = (size) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < size; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const getXheaders = () => {
  try {
    const xh1 = Date.now();
    const hst = window.location.host;
    const xn2 = (xh1 - 3000);
    const h1r = window.btoa(hst);
    const h2r = window.btoa(`${h1r}:${xh1}`);
    const f1 = getRchar(1);
    const xh2 = `x${f1}${h2r}`;
    const f2 = getRchar(30);
    const f3 = xh2.at(-1);
    const ctoken = `${f3}${f2}${f1}`;
    const nonce = window.btoa(xn2);
    const {
      xpid,
      xsn,
    } = getPageAndSiteKeys();
    const lkey = `xsk-${xpid}:${xsn}`;
    let skData = localStorage.getItem(lkey);
    let xapiKey = '';
    let xapiSource = ''
    if(!skData) {
      // skData = localStorage.getItem('xskey');
    }
    if (skData) {
      const {
        keyValue,
        sourceName,
      } = JSON.parse(skData) || {};
      if(keyValue) {
        xapiKey = keyValue;
      }
      if(sourceName) {
        xapiSource = sourceName;
      }
    }
    const h = {
      'x-xapp': xh2,
      'x-xts': xh1,
      csrftoken: ctoken,
      'x-xnonce': nonce,
    };
    if(xapiKey) {
      h['x-api-key'] = xapiKey;
    }
    if(xapiSource) {
      h['x-api-source'] = xapiSource;
    }
    return h;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export {
  getRchar,
  getXheaders,
};
