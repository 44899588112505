import axios from "axios";
import { API_V2_URL, API_V1_URL } from "constants/api";
import { getXheaders } from "helpers/xheaders";

const xapi = axios.create({
  baseURL: API_V2_URL,
  headers: {
    "Content-Type": 'application/json',
  },
});

xapi.interceptors.request.use( (request) => {
  const xh = getXheaders();
  const rheaders = request.headers;
  let fheaders = rheaders;
  if(rheaders) {
    fheaders = {
      ...rheaders,
      ...xh,
    };
  }
  request.headers = fheaders;
  return request;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export default xapi;

const xrvapi = axios.create({
  baseURL: API_V1_URL,
  headers: {
    "Content-Type": 'application/json',
    "x-api-source": 'x',
    "x-api-key": 'xyz',
  },
});

export { xrvapi };

