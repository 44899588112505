// const API_V2_BASE = 'http://localhost:4650';
const API_V2_BASE = 'https://staffing.x0pa.ai';
const API_V2_URL = `${API_V2_BASE}/roboroy/api/v2`;
const API_V1_URL = `https://staffing.x0pa.ai/roboroy/api/v1`;
console.log("CONSTANTS:::",window.location.host,document.referrer);
export {
  API_V2_BASE,
  API_V2_URL,
  API_V1_URL,
};
