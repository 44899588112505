import { Flex } from '@chakra-ui/react';
import { useGetFunctions } from 'hooks/useFunctions';
import XDropdown from "containers/XDropdown";

const expyears = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20
].map(e => {
  return {
    label: e,
    value: e,
  }
});

const expmonths = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
].map(e => {
  return {
    label: e,
    value: e,
  }
});

export default function XExperience(props) {
  const {
    xlabel = "Experience",
    xplaceholder = "Exp ?",
    xoptions,
    xcontrol,
    xid,
    isRequired,
    haveExp,
    ...rprops
  } = props || {};
  const xname = `${xid}-exp-bool`;
  const xnameyrs = `${xid}-exp-years`;
  const xnamemths = `${xid}-exp-months`;

  return (
    <Flex flex={1} className="xcs-exp-dropdown">
      <XDropdown
        control={xcontrol}
        isMulti={false}
        name={xname}
        id={xname}
        options={[{
          value: 'Yes',
          label: 'Yes',
        }, {
          value: 'No',
          label: 'No',
        }]}
        placeholder={xplaceholder}
        label={xlabel}
        classNamePrefix="xcs"
        isRequired={isRequired}
        fclassName="x-exp-bool"
        {...rprops}
      />
      {
        haveExp && (
          <>
            <XDropdown
              control={xcontrol}
              isMulti={false}
              name={xnameyrs}
              id={xnameyrs}
              options={expyears}
              placeholder="Years"
              label="Years"
              classNamePrefix="xcs"
              fclassName="ml-2"
              {...rprops}
            />
            <XDropdown
              control={xcontrol}
              isMulti={false}
              name={xnamemths}
              id={xnamemths}
              options={expmonths}
              placeholder="Months"
              label="Months"
              classNamePrefix="xcs"
              fclassName="ml-2"
              {...rprops}
            />
          </>
        )
      }


    </Flex>
  )
}