
const getFilterName = (n, full = false) => {
  if(!n) {
    return null;
  }
  if(n.includes('function')) {
    if(full) {
      return 'function';
    }
    return 'f';
  }
  if(n.includes('jobtype')) {
    if(full) {
      return 'jobType';
    }
    return 'j';
  }
  if(n.includes('jtorkw')) {
    if(full) {
      return 'searchKey';
    }
    return 'kw';
  }
  if(n.includes('location')) {
    if(full) {
      return 'location';
    }
    return 'l';
  }
  if(n.includes('max-jexp')) {
    if(full) {
      return 'maxExp';
    }
    return 'xex';
  }
  if(n.includes('min-jexp')) {
    if(full) {
      return 'minExp';
    }
    return 'nex';
  }
  if(n.includes('qual')) {
    if(full) {
      return 'qualType';
    }
    return 'q';
  }
  if(n.includes('sector')) {
    if(full) {
      return 'sector';
    }
    return 's';
  }
  if(n.includes('state')) {
    if(full) {
      return 'state';
    }
    return 'st';
  }
  if(n.includes('company')) {
    if(full) {
      return 'company';
    }
    return 'co';
  }
  if(n.includes('businessUnit')) {
    if(full) {
      return 'businessUnit';
    }
    return 'bu';
  }
  if(n.includes('limit')) {
    if(full) {
      return 'limit';
    }
    return 'limit';
  }
  if(n.includes('offset')) {
    if(full) {
      return 'offset';
    }
    return 'offset';
  }
  // return n;
};

const getQkeyFromFilters = (data, inKey) => {
  // console.log("DTA", data, inKey);
  const filters = {
    x: inKey,
    f: '-',
    j: '-',
    kw: '-',
    l: '-',
    xex: '-',
    nex: '-',
    q: '-',
    s: '-',
  };
  if(data && Object.keys(data).length > 0) {
    Object.keys(data).forEach(s => {
      const v = data[s];
      const n = getFilterName(s);
      if(v || (v == '0')) {
        try {
          if(Object.keys(v).length && (v.label || v.label == '0')) {
            filters[n] = v.label;
          } else {
            filters[n] = v;
          }
        } catch(e) {
          filters[n] = v;
        }
      }
    });
  }
  return filters;
};

const getJobFiltersFromKey = (data) => {
  // console.log("FILTERS", data);
  const filters = {};
  if(data && Object.keys(data).length > 0) {
    Object.keys(data).forEach(s => {
      const v = data[s];
      const n = getFilterName(s, true);
      if(s == 'minExp') {
        // console.log("SSS", s, v)
      }
      if(v || (v == '0')) {
        try {
          if(Object.keys(v).length && (v.label || v.label == '0')) {
            filters[n] = (v.label + "").toLowerCase();
          } else {
            filters[n] = (v + "").toLowerCase();
          }
        } catch(e) {
          filters[n] = (v + "").toLowerCase();
        }
      }
    });
  }
  // console.log("FILTERSObj", filters);
  return filters;
};

const getFileBase64 = async (file) => {
  try {
    return new Promise(function(resolve, reject) {
      const reader = new FileReader();
      reader.onload = function() {
        const base64 = reader.result.split (",").pop ()
        resolve(base64);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  } catch(err) {
    console.error(err);
    return null;
  }
}

export const getBrowserLang = () =>{
  if (navigator.languages != undefined) {
    return navigator.languages[0];
  }
  return navigator.language;
}

export const xformatNumber = (xnum, locale) => {
  try {
    const blang = getBrowserLang();
    return new Intl.NumberFormat(locale || blang || 'en-IN').format(Math.round(xnum));
  } catch (error) {
    return xnum;
  }
}

export const getLocaleNumber = (num, locale) => {
  try {
    if(!num) {
      return 0;
    }
    let numVal = Number(num || 0);
    if(isNaN(numVal)) {
      return num;
    }
    let finalLocale = getBrowserLang();
    if (locale) {
      finalLocale = locale;
    }
    if(finalLocale) {
      return new Intl.NumberFormat(finalLocale).format(numVal);
    } else {
      return numVal;
    }
  } catch (error) {
    console.error(error);
    return num;
  }
};

export {
  getQkeyFromFilters,
  getJobFiltersFromKey,
  getFileBase64,
};
