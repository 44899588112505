import { useQuery } from "react-query";

import xapi from "helpers/fetch";
import { getQkeyFromFilters, getJobFiltersFromKey } from "helpers/utils";

const getJobs = async (rdata) => {
  try {
    const xurl = `/jobs/p/list/all`;
    const config = {
      url: xurl,
      method: 'get',
    }
    const { siteData, ...filters} = rdata || {};
    if(filters) {
      config.params = {
        ...filters,
      };
    }
    const j = await xapi(config);

    const { data: k } = j;
    return k || {};
  } catch (err) {
    console.error(err);
    throw err;
  }
};


export const useGetJobs = (data, x2) => {
  let qkey = getQkeyFromFilters(data, 'xjobs');
  const filters = getJobFiltersFromKey(data);
  const usp = new URLSearchParams(window.location.search);
  const jctags = usp.get("jctags");
  if (jctags && filters) {
    filters.jtags = jctags;
  }
  if(!qkey) {
    qkey = {
      x: 'xjobs',
    };
  }
  const { siteData } = data || {};
  console.log('siteData2:',data, x2);
  const { keyName, sourceName } = siteData || {};
  let sprefix = 'x-';
  if(keyName && sourceName) {
    sprefix = `${keyName}:${sourceName}`;
  }
  const xdata = useQuery(
    [sprefix, 'xjobs', qkey], () => getJobs(filters),
    {
      refetchOnWindowFocus: false,
      retry: 3,
    }
  );
  return xdata;
};
