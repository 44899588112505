import { Link } from "react-router-dom";
import { Box, Flex, Button, Text, Image } from '@chakra-ui/react';
import { MdLocationOn, MdBookmark, MdDateRange } from "react-icons/md";
import { IoCashOutline } from "react-icons/io5";
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useCompanyInfo } from 'hooks/useCompanyInfo';
import { getLocaleNumber } from "helpers/utils";

export default function XJob(props) {
  const { t } = useTranslation();
  const cinfo = useCompanyInfo();
  const {
    logo,
    xconfig,
  } = (cinfo && cinfo.data) || {};
  const {
    xcdomainUrl,
    applyExternally,
  } = xconfig || {};
  const { xdata, jobDetailPage, hideApplyOrViewButton, careersWebSite } = props || {};
  let showApplyLink = false;
  if (jobDetailPage) {
    showApplyLink = true;
  }
  const {
    jobId,
    jobName,
    workLocation,
    countryName,
    stateName,
    cityName,
    minExp,
    maxExp,
    region,
    openDate,
    updateDate,
    minSalary,
    maxSalary,
  } = xdata || {};
  let expRange = '';
  if (minExp && maxExp) {
    expRange = `${minExp} - ${maxExp} yrs`;
  }
  if (minExp && !maxExp) {
    expRange = `Min. ${minExp} yrs`;
  }
  if (maxExp && !minExp) {
    expRange = `Max. ${maxExp} yrs`;
  }
  if (maxExp <= 1) {
    expRange = 'Fresher';
  }
  let locString = '';
  if (workLocation) {
    locString = workLocation || '';
  }
  if (region) {
    if (locString) {
      locString += `, ${region}`;
    } else {
      locString = region;
    }
  }
  if (countryName && (countryName != 'India')) {
    if (locString) {
      locString += `, ${countryName}`;
    } else {
      locString = countryName;
    }
  }

  if (!locString && workLocation) {
    locString = workLocation;
  }
  if (!locString) {
    locString = "Not specified";
  }

  let salaryStr;
  if(minSalary) {
    salaryStr = getLocaleNumber(minSalary);
  }
  if(maxSalary) {
    const maxSalVal = getLocaleNumber(maxSalary);
    if(salaryStr) {
      salaryStr += ` - ${maxSalVal}`;
    } else {
      salaryStr = maxSalVal;
    }
  }
  const baseJobURL = careersWebSite ? '/careers/jobs': '/jobs';
  let externalBaseUrl = "https://staffing.x0pa.ai";
  if(xcdomainUrl) {
    externalBaseUrl = xcdomainUrl;
  }
  const externalApplyUrl = `${externalBaseUrl}/app/roboroy/job/${jobId}/apply?ref=careersite`;
  return (
    <Box
      className="xcs-job-details"
    >
      <Flex
        className="xjd-wrap"
        boxShadow="1px 0px 20px rgba(0,0,0,0.07)"
        p='8'
        rounded='md'
        borderRadius={4}
        borderLeft="1px solid #b51c0e"
        justifyContent="center"
        alignItems="center"
        // height="150px"
        maxH="150"
        flexWrap="wrap"
      >
        {
          logo && (
            <Flex
              mr={2}
              width={100}
              justifyContent="center"
              className="x-jb-logo-wrap"
            >
              <Image
                alt="Logo"
                src={logo}
              />
            </Flex>
          )
        }
        <Flex direction="column" flex={1}>
          <Text as="span" fontSize="smaller" mb="1">{t("ID")}: {jobId}</Text>
          <Text as="h4" fontWeight="bold" fontSize="18px">
            <Link to={`${baseJobURL}/${jobId}`}>
              {jobName}
            </Link>
          </Text>
          <Flex
            alignItems="center"
          >
            <MdLocationOn />
            <Text title={locString} ml={1} mr={2} style={{ maxWidth: 200, overflow: "hidden", whiteSpace: 'nowrap', textOverflow: "ellipsis" }}>{locString}</Text>
            {
              salaryStr && (
                <>
                  <IoCashOutline />
                  <Text title={salaryStr} ml={1} mr={2} style={{ maxWidth: 200, overflow: "hidden", whiteSpace: 'nowrap', textOverflow: "ellipsis" }}>{salaryStr}</Text>
                </>
              )
            }
            <MdDateRange />
            <Text ml={1}>{moment((updateDate || openDate)).fromNow()}</Text>
          </Flex>
        </Flex>

        <Flex direction="column">
          {
            !hideApplyOrViewButton && showApplyLink && !applyExternally && (
              <Button
                colorScheme='blue'
                rounded="2rem"
                as={Link}
                to={`${baseJobURL}/${jobId}/c/apply`}
              >
                {t("Apply now")}
              </Button>
            )
          }
          {
            !hideApplyOrViewButton && showApplyLink && applyExternally && (
              <Button
                colorScheme='blue'
                rounded="2rem"
                as="a"
                href={externalApplyUrl}
                target="_blank"
              >
                {t("Apply now")}
              </Button>
            )
          }
          {
            !hideApplyOrViewButton && !showApplyLink && (
              <Button
                colorScheme='blue'
                rounded="2rem"
                as={Link}
                to={`${baseJobURL}/${jobId}`}
              >
                {t("View")}
              </Button>
            )
          }
        </Flex>
      </Flex>
    </Box>
  )
}