import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import XDropdown from "containers/XDropdown";

const qual = [
  {
    "value": 14,
    "label": "Any Graduation",
  },
  {
    "value": 13,
    "label": "Medicine",
  },
  {
    "value": 12,
    "label": "PhD",
  },
  {
    "value": 11,
    "label": "Master's Degree",
  },
  {
    "value": 10,
    "label": "Bachelor's Degree",
  },
  {
    "value": 9,
    "label": "Associate Degree",
  },
  {
    "value": 8,
    "label": "Diploma",
  },
  {
    "value": 6,
    "label": "Certification",
  },
  {
    "value": 5,
    "label": "High School or Equivalent",
  },
  {
    "value": 4,
    "label": "Upper Secondary Education",
  },
  {
    "value": 3,
    "label": "Lower Secondary Education",
  },
  {
    "value": 2,
    "label": "Primary Education",
  },
  {
    "value": 1,
    "label": "Early Childhood Education",
  }
];

export default function XQual(props) {
  const { t } = useTranslation();
  const label = t("Min. Qualification");
  const ph = t("Select Job Type");
  const {
    xlabel = label,
    xplaceholder = ph,
    xoptions,
    xcontrol,
    xid,
    ...rprops
  } = props || {};
  let options = qual;
  if (Array.isArray(xoptions)) {
    options = xoptions;
  }
  const xname = `${xid}-qual`;

  return (
    <Flex flex={1} className="xcs-qual-dropdown">
      <XDropdown
        control={xcontrol}
        isMulti={false}
        name={xname}
        id={xname}
        options={options}
        placeholder={xplaceholder}
        label={xlabel}
        classNamePrefix="xcs"
        {...rprops}
      />
    </Flex>
  )
}