import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import XDropdown from "containers/XDropdown";

const exOptions = [
  {
      "label": 0,
      "value": 0
  },
  {
      "label": 1,
      "value": 1
  },
  {
      "label": 2,
      "value": 2
  },
  {
      "label": 3,
      "value": 3
  },
  {
      "label": 4,
      "value": 4
  },
  {
      "label": 5,
      "value": 5
  },
  {
      "label": 6,
      "value": 6
  },
  {
      "label": 7,
      "value": 7
  },
  {
      "label": 8,
      "value": 8
  },
  {
      "label": 9,
      "value": 9
  },
  {
      "label": 10,
      "value": 10
  },
  {
      "label": 11,
      "value": 11
  },
  {
      "label": 12,
      "value": 12
  },
  {
      "label": 13,
      "value": 13
  },
  {
      "label": 14,
      "value": 14
  },
  {
      "label": 15,
      "value": 15
  },
  {
      "label": 20,
      "value": 20
  },
  {
      "label": 25,
      "value": 25
  },
  {
      "label": 30,
      "value": 30
  },
  {
      "label": 35,
      "value": 35
  }
];

export default function XJExp(props) {
  const { t } = useTranslation();
  const label = t("Experience");
  const ph = t("Select Experience");
  const {
    xlabel = label,
    xplaceholder = ph,
    xoptions,
    xcontrol,
    xid,
    xnameprefix,
  } = props || {};
  let options = exOptions;
  if (Array.isArray(xoptions)) {
    options = xoptions;
  }
  const xname = `${xid}-${xnameprefix}-jexp`;

  return (
    <Flex maxW={120} flex={1} className="xcs-jexp-dropdown">
      <XDropdown
        control={xcontrol}
        isMulti={false}
        name={xname}
        id={xname}
        options={options}
        placeholder={xplaceholder}
        label={xlabel}
        classNamePrefix="xcs"
      />
    </Flex>
  )
}
