import { Container, Box, Flex, Link, Button } from "@chakra-ui/react";
import { useGetCities } from "hooks/useCities";
import XHome from 'routes/XHome';

export default function XCareers(props) {
  const { siteData } = props;
  const bannerURL =
    "http://www.stoneforest.com.sg/images/default-source/default-album/careers-1.jpg?sfvrsn=17f9981c_2";
  return (
    <Box>
      <Box
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        style={{ height: 300 }}
        backgroundImage={bannerURL}
      ></Box>
      <XHome careersWebSite  siteData={siteData} />
    </Box>
  );
}
