import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  Box,
  Flex,
  Container,
  Text,
  Button,
} from '@chakra-ui/react';
import { useTranslation, } from 'react-i18next';


function XLayout() {
  const { i18n, t } = useTranslation();
  let lngs = {
    en: { nativeName: 'en' },
    it: { nativeName: 'it' }
  };
  const usp = new URLSearchParams(window.location.search);
  const lngStr = usp.get('xlngs');
  if(lngStr) {
    const lngArr = lngStr.split(",");
    if(Array.isArray(lngArr) && lngArr.length) {
      lngs = {};
      lngArr.forEach(lg => {
        lngs[`${lg}`] = { nativeName: lg };
      });
    }
  }
  
  return (
    <div className="x-app-wrap">
      <Box my={4}>
        <Container maxW="1200px" className="x-lay-con">
          <Flex className="x-lang" justifyContent="flex-end" alignItems="center">
            <Text mx={2}>{ t("Language") }:</Text>
            {Object.keys(lngs).map((lng) => (
              <Button
                key={lng}
                style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }}
                type="submit"
                onClick={() => i18n.changeLanguage(lng)}
                variant="ghost"
              >
                {lngs[lng].nativeName}
              </Button>
            ))}
          </Flex>
          <Outlet />
        </Container>
      </Box>
    </div>
  );
}

export default XLayout;
