import { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import {
  useToast,
  Text,
} from "@chakra-ui/react";
import { useTranslation, } from 'react-i18next';

import XLayout from 'containers/XLayout';
import XHome from 'routes/XHome';
import XJobDetail from 'containers/XJobDetail';
import XJobApply from 'containers/XJobApply';
import XTPUpload from 'containers/XTPUpload';
import XCareers from 'containers/XCareers';
import XCareersLayout from 'containers/XCareersLayout';
import { useSiteKey } from 'hooks/useSiteKey';

const XRoutes = () => {
  const [siteData, setSiteData] = useState({});
  const { t } = useTranslation();
  const toast = useToast();

  const { mutate } = useSiteKey((err, skData) => {
    const { data: sdata, error: serr } = skData || {};
    const { error: terr } = sdata || {};
    console.log('skData', skData, terr);
    if(terr || serr) {
      // toast({
      //   status: 'error',
      //   duration: 10000,
      //   isClosable: true,
      //   render: () => (
      //     <Text>
      //       {t("Unable to connect")}!
      //     </Text>
      //   ),
      // });
    } else {
      setSiteData(skData);
    }
  });

  useEffect(() => {
    mutate();
  }, [mutate]);
  
  const x = window.xgpid;
  const s = window.xgsn;
  let clsNames = "";
  if(x) {
    clsNames += ` x-gp-${x}`;
  }
  if(s) {
    clsNames += ` x-sp-${s}`;
  }

  return (
    <div className={clsNames}>
      <BrowserRouter>
        <Routes>
          <Route path="/careers" element={<XCareersLayout siteData={siteData} />}>
            <Route index element={<XCareers siteData={siteData} />} />
            <Route path="c/r/tp" element={<XTPUpload careersWebSite siteData={siteData} />} />
            <Route path="jobs">
              <Route index element={<XCareers siteData={siteData} />} />
              <Route path=":jobId">
                <Route index element={<XJobDetail careersWebSite siteData={siteData} />} />
                <Route path="n/:jobName" element={<XJobDetail careersWebSite siteData={siteData} />} />
                <Route path="c/apply" element={<XJobApply careersWebSite siteData={siteData} />} />
              </Route>
            </Route>
          </Route>
          <Route path="/" element={<XLayout siteData={siteData} />}>
            <Route index element={<XHome siteData={siteData} />} />
            <Route path="c/r/tp" element={<XTPUpload siteData={siteData} />} />
            <Route path="jobs">
              <Route path=":jobId">
                <Route index element={<XJobDetail siteData={siteData} />} />
                <Route path="n/:jobName" element={<XJobDetail siteData={siteData} />} />
                <Route path="c/apply" element={<XJobApply siteData={siteData} />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
};

export default XRoutes;
