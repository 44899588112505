import { useQuery, useMutation } from "react-query";

import xapi from "helpers/fetch";
import { getPageAndSiteKeys } from "helpers/common";

const getSiteKey = async (rdata) => {
  try {
    const xurl = `/xint/key/details`;
    const config = {
      url: xurl,
      method: 'post',
    }
    if(rdata) {
      config.data = rdata;
    }
    const j = await xapi(config);
    const { data: k } = j;
    const {
      keyName,
      sourceName,
    } = k || {};
    window.xgpid = keyName;
    window.xgsn = sourceName;
    const lkey = `${keyName}:${sourceName}`;
    localStorage.setItem(`xsk-${lkey}`, JSON.stringify(k));
    localStorage.setItem('xskey', JSON.stringify(k));
    return k || {};
  } catch (err) {
    console.error(err);
    return {
      error: true,
    };
  }
};


export const useSiteKey = (cb) => {
  const {
    xpid,
    xsn,
    xorigin,
  } = getPageAndSiteKeys();
  const data = {
    xorigin,
    sourceName: xsn,
    keyName: xpid,
  };

  const xdata = useMutation(() => getSiteKey(data),
    {
      onSuccess: (sdata) => {
        cb(null, sdata);
      },
      onError: () => {
        cb("Error occured while processing.", null);
      },
      onSettled: () => {

      }
    }
  );

  // const xdata = useQuery(
  //   ['xskey', xpid], () => getSiteKey(data),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  return xdata;
};
